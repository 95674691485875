import type { CustomOptionsProps } from '../../types';

export const customOptions: CustomOptionsProps = {
  languageCode: 'en',
  regionISO: 'US',
  cookieManagementEnabled: true,
  googleAdsEnabled: false,
  headerLiftEnabled: true,
  googleSearchConsoleId: '',
  googleTagManagerCode: '',
  googleAnalyticsId: 'G-SQXRKSHVZH',
  facebookTrackingId: '',
  twitterHandle: '@gamedistribution',
  twitterSiteHandle: '@azerion',
  appIconId: '2b395754-da2f-4518-9fd8-facb575ef4e7',
  headerLogoId: 'f18a20a8-05d1-42df-aa0b-319f7b6e5837',
  footerLogoId: '2e6a653e-fd16-4ddb-87e6-62ef4790b65f',
  fontUrl: 'https://fonts.googleapis.com/css2?family=Quicksand&display=swap',
  defaultTitle: 'Games 2 Girls',
  titleTemplate: 'or-site-games-2-girls | %s',
  noFollow: true,
  noIndex: true,
  cookieLawProId: '2f4bbf78-9ef9-4acd-817e-f3398569abf6',
  extraHeaderURL: '',
  extraFooterURL: '',
  extraHeaderStyleURL: '',
  extraFooterStyleURL: '',
  customCss: ``,
  scripts: [
    {
      src: 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
      strategy: 'afterInteractive',
      charset: 'UTF-8',
      'data-domain-script': '2f4bbf78-9ef9-4acd-817e-f3398569abf6'
    },
    {
      id: 'ot-callback-script', // Required when using dangerouslySetInnerHTML.
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            function OptanonWrapper() {
            };
          `
      }
    },
    {
      id: 'idhb', // Required by HeaderLift.
      src: 'https://hb.improvedigital.com/pbw/headerlift.min.js',
      strategy: 'afterInteractive'
    },
    {
      // Todo: Nunjucks the GA tracking IDs.
      src: `https://www.googletagmanager.com/gtag/js?id=G-SQXRKSHVZH`,
      strategy: 'afterInteractive'
    },
    {
      id: 'gtag-datalayer-script', // Required when using dangerouslySetInnerHTML.
      strategy: 'afterInteractive',
      dangerouslySetInnerHTML: {
        __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-SQXRKSHVZH', {
              page_path: window.location.pathname,
            });
          `
      }
    },
  ],
  links: [
  ],
};